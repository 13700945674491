<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">信探官网管理系统</div>
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="login"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="name">
          <el-input v-model="loginForm.name" placeholder="name">
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="password"
            v-model="loginForm.password"
            @keyup.enter="submitForm()"
          >
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 0px">
          <!-- <img :src="src" alt="" class="code_img" /> -->
        </el-form-item>
        <el-form-item prop="captcha" class="ipt">
          <el-input
            type="text"
            placeholder="验证码"
            v-model="loginForm.captcha"
          >
            <template v-slot:append>
              <el-button
                icon="el-icon-refresh-left"
                @click="refresh"
              ></el-button>
            </template>
          </el-input>
          <img :src="src" alt="" class="code_img" />
        </el-form-item>

        <div class="login-btn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </div>
        <p class="login-tips">Tips : 输入正确的用户名和密码</p>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        name: "",
        password: "",
        captcha: "",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      loading: false,
      src: "",
    };
  },
  created() {
    // console.log(document.cookie);
    this.$store.commit("clearTags");
    this.getData();
  },
  methods: {
    refresh() {
      this.getData();
    },
    async getData() {
      // dream-captcha=d9e55b91-5e80-4dc2-99a2-5284080872b8
      const res = await this.$api.getCode();

      let url = window.URL.createObjectURL(res);

      this.src = url;

      // if (res.code === 0) {
      //   console.log(res);
      // } else {
      //   this.$message.error(res.msg);
      // }
    },
    submitForm() {
      if (!this.loading) {
        this.loading = true;
        this.$refs["login"].validate((valid) => {
          if (valid) {
            this.$store
              .dispatch("usernameLogin", this.loginForm)
              .then((res) => {
                if (res.code === 0) {
                  this.$router.push("/information");
                } else {
                  this.$message.error(res.msg);
                  this.getData();
                }
              })
              .catch(() => {});
            this.loading = false;
          } else {
            this.$message.error("请输入账号,密码和验证码");
            return false;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
.ipt {
  position: relative;
}
.code_img {
  position: absolute;
  top: 1px;
  left: 160px;
  width: 25%;
  height: 38px;
}
</style>
